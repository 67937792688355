var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mflindex"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.projectData.project)+" ")]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"leftmain fl"},[_c('div',{staticClass:"left-widthx1 color1 left-jg"},[_vm._v("正平衡")]),_c('div',{staticClass:"left-widthx1 color1 left-jg1"},[_vm._v("反平衡")]),_c('div',{staticClass:"left-widthx1 color1 left-jg2"},[_vm._v("汽压SP")]),_c('div',{staticClass:"left-widthx1 color1 left-jg3"},[_vm._v("汽压PV")]),_c('div',{staticClass:"left-widthx1 color1 left-jg4"},[_vm._v("负荷PV")]),_c('div',{staticClass:"left-widthx1 color1 left-jg4"},[_vm._v("分配负荷")]),_c('div',{staticClass:"left-widthx2 left-jg5",on:{"click":function($event){return _vm.toCompon(5, 'E', '5')}}},[_vm._v(" E磨 ")]),_c('div',[_c('div',{staticClass:"bottonx fl",style:({
              background:
                _vm.infoList.XK__p__GMJ_XK5.RM && !_vm.infoList.XK__p__GMJ_XK5.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.XK__p__GMJ_XK5
                ? _vm.toDetail(
                    1,
                    'GMJ_XK5',
                    'XK__p__GMJ_XK5',
                    '',
                    'E给煤机先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background: !_vm.infoList.XK__p__MFL_XK5.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.XK__p__MFL_XK5
                ? _vm.toDetail(
                    1,
                    'MFL_XK5',
                    'XK__p__MFL_XK5',
                    '',
                    'E磨风量先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background: !_vm.infoList.XK__p__CKT_XK5.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.XK__p__CKT_XK5
                ? _vm.toDetail(
                    1,
                    'CKT_XK5',
                    'XK__p__CKT_XK5',
                    '',
                    'E磨出口温度先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background:
                _vm.infoList.RQM__p__GMJ_MAN5.RM == 1 ||
                _vm.infoList.RQM__p__MFL_MAN5.RM == 1 ||
                _vm.infoList.RQM__p__CKT_MAN5.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANEM', 3, 'E', '5')}}},[_vm._v(" A ")])]),_c('div',{staticClass:"left-widthx2 left-jg6",on:{"click":function($event){return _vm.toCompon(5, 'D', '4', '油压', 'MYP')}}},[_vm._v(" D磨 ")]),_c('div',[_c('div',{staticClass:"bottonx fl",style:({
              background: !_vm.infoList.XK__p__GMJ_XK4.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.XK__p__GMJ_XK4
                ? _vm.toDetail(
                    1,
                    'GMJ_XK4',
                    'XK__p__GMJ_XK4',
                    '',
                    'D给煤机先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background: !_vm.infoList.XK__p__MFL_XK4.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.XK__p__MFL_XK4
                ? _vm.toDetail(
                    1,
                    'MFL_XK4',
                    'XK__p__MFL_XK4',
                    '',
                    'D磨风量先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background: !_vm.infoList.XK__p__CKT_XK4.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.XK__p__CKT_XK4
                ? _vm.toDetail(
                    1,
                    'CKT_XK4',
                    'XK__p__CKT_XK4',
                    '',
                    'D磨出口温度先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background:
                _vm.infoList.RQM__p__GMJ_MAN4.RM == 1 ||
                _vm.infoList.RQM__p__MFL_MAN4.RM == 1 ||
                _vm.infoList.RQM__p__CKT_MAN4.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANDM', 3, 'D', '4')}}},[_vm._v(" A ")])]),_c('div',{staticClass:"left-widthx2 left-jg6",on:{"click":function($event){return _vm.toCompon(5, 'C', '3')}}},[_vm._v(" C磨 ")]),_c('div',[_c('div',{staticClass:"bottonx fl",style:({
              background: !_vm.infoList.XK__p__GMJ_XK3.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.XK__p__GMJ_XK3
                ? _vm.toDetail(
                    1,
                    'GMJ_XK3',
                    'XK__p__GMJ_XK3',
                    '',
                    'C给煤机先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background: !_vm.infoList.XK__p__MFL_XK3.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.XK__p__MFL_XK3
                ? _vm.toDetail(
                    1,
                    'MFL_XK3',
                    'XK__p__MFL_XK3',
                    '',
                    'C磨风量先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background: !_vm.infoList.XK__p__CKT_XK3.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.XK__p__CKT_XK3
                ? _vm.toDetail(
                    1,
                    'CKT_XK3',
                    'XK__p__CKT_XK3',
                    '',
                    'C磨出口温度先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background:
                _vm.infoList.RQM__p__GMJ_MAN3.RM == 1 ||
                _vm.infoList.RQM__p__MFL_MAN3.RM == 1 ||
                _vm.infoList.RQM__p__CKT_MAN3.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANCM', 3, 'C', '3')}}},[_vm._v(" A ")])]),_c('div',{staticClass:"left-widthx2 left-jg6",on:{"click":function($event){return _vm.toCompon(5, 'B', '2')}}},[_vm._v(" B磨 ")]),_c('div',[_c('div',{staticClass:"bottonx fl",style:({
              background: !_vm.infoList.XK__p__GMJ_XK2.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.XK__p__GMJ_XK2
                ? _vm.toDetail(
                    1,
                    'GMJ_XK2',
                    'XK__p__GMJ_XK2',
                    '',
                    'B给煤机先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background: !_vm.infoList.XK__p__MFL_XK2.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.XK__p__MFL_XK2
                ? _vm.toDetail(
                    1,
                    'MFL_XK2',
                    'XK__p__MFL_XK2',
                    '',
                    'B磨风量先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background: !_vm.infoList.XK__p__CKT_XK2.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.XK__p__CKT_XK2
                ? _vm.toDetail(
                    1,
                    'CKT_XK2',
                    'XK__p__CKT_XK2',
                    '',
                    'B磨出口温度先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background:
                _vm.infoList.RQM__p__GMJ_MAN2.RM == 1 ||
                _vm.infoList.RQM__p__MFL_MAN2.RM == 1 ||
                _vm.infoList.RQM__p__CKT_MAN2.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANBM', 3, 'B', '2')}}},[_vm._v(" A ")])]),_c('div',{staticClass:"left-widthx2 left-jg6",on:{"click":function($event){return _vm.toCompon(5, 'A', '1')}}},[_vm._v(" A磨 ")]),_c('div',[_c('div',{staticClass:"bottonx fl",style:({
              background: !_vm.infoList.XK__p__GMJ_XK1.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.XK__p__GMJ_XK1
                ? _vm.toDetail(
                    1,
                    'GMJ_XK1',
                    'XK__p__GMJ_XK1',
                    '',
                    'A给煤机先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background: !_vm.infoList.XK__p__MFL_XK1.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.XK__p__MFL_XK1
                ? _vm.toDetail(
                    1,
                    'MFL_XK1',
                    'XK__p__MFL_XK1',
                    '',
                    'A磨风量先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background: !_vm.infoList.XK__p__CKT_XK1.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.XK__p__CKT_XK1
                ? _vm.toDetail(
                    1,
                    'CKT_XK1',
                    'XK__p__CKT_XK1',
                    '',
                    'A磨出口温度先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background:
                _vm.infoList.RQM__p__GMJ_MAN1.RM == 1 ||
                _vm.infoList.RQM__p__MFL_MAN1.RM == 1 ||
                _vm.infoList.RQM__p__CKT_MAN1.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANAM', 3, 'A', '1')}}},[_vm._v(" A ")])]),_c('div',{staticClass:"left-widthx2 left-jg6",staticStyle:{"margin-top":"5vh"},on:{"click":function($event){return _vm.toCompon(14)}}},[_vm._v(" 再热汽温 ")]),_c('div',[_c('div',{staticClass:"bottonx fl",style:({
              background: !_vm.infoList.XK__p__ZQT_XK9.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.XK__p__ZQT_XK9
                ? _vm.toDetail(
                    1,
                    'ZQT_XK9',
                    'XK__p__ZQT_XK9',
                    '',
                    '再热汽烟气挡板'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background: !_vm.infoList.XK__p__ZQT_XK10.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.XK__p__ZQT_XK10
                ? _vm.toDetail(
                    1,
                    'ZQT_XK10',
                    'XK__p__ZQT_XK10',
                    '',
                    '再热汽流量控制'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background: !_vm.infoList.RQM__p__ZRQ_RSF1.TS ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.RQM__p__ZRQ_RSF1
                ? _vm.toDetail(
                    3,
                    'ZRQ_RSF1',
                    'RQM__p__ZRQ_RSF1',
                    '',
                    '再热器流量补补偿'
                  )
                : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background:
                _vm.infoList.RQM__p__ZQT_MAN5.RM == 1 ||
                _vm.infoList.RQM__p__ZQT_MAN6.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANZR', 2)}}},[_vm._v(" A ")])])]),_c('div',{staticClass:"fl maincenter"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"waterline"},[_c('div',{staticClass:"shuiweisp"},[_c('div',{staticClass:"fl widthx1 color1 col1jg"},[_vm._v("水位SP")]),_c('div',{staticClass:"fl valuex color2",on:{"click":function($event){return _vm.toCompon(2, 'QBW_SP', 'IOG', 'QBW_SP_BM', '水位设定值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.IOG.QBW_SP, 'QBW_SP', 'IOG', 'QBW_SP_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.QBW_SP)+"mm ")])]),_c('div',{staticClass:"col1jg1"},[_c('div',{staticClass:"fl widthxx",staticStyle:{"margin-left":"0.8vw"},style:({
                  background: _vm.infoList.MKG.QBW_SEL == 1 ? '#2AFC30' : 'red',
                }),on:{"click":function($event){return _vm.toIpt('1', '水位1', 'QBW_SEL', 'MKG', 'true')}}},[_vm._v(" 水位1 ")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'QBW_A_PV',
                    'IOG',
                    'QBW_A_PV_BM',
                    '汽包水位过程值1'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.IOG.QBW_A_PV,
                    'QBW_A_PV',
                    'IOG',
                    'QBW_A_PV_BM'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.QBW_A_PV)+"mm ")])]),_c('div',{staticClass:"col1jg2"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(2, 'RSXLSS', 'KHG', 'RSXLSS_BM', '燃烧效率')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.IOG.QBW_A_PV, 'RSXLSS', 'KHG', 'RSXLSS_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.KHG.RSXLSS)+" ")]),_c('div',{staticClass:"fl widthxx",staticStyle:{"margin-left":"0.8vw"},style:({
                  background: !_vm.infoList.MKG.QBW_SEL == 2 ? '#2AFC30' : 'red',
                }),on:{"click":function($event){return _vm.toIpt('2', '水位2', 'QBW_SEL', 'MKG', 'true')}}},[_vm._v(" 水位2 ")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'QBW_B_PV',
                    'IOG',
                    'QBW_B_PV_BM',
                    '汽包水位过程值2'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.IOG.QBW_A_PV,
                    'QBW_B_PV',
                    'IOG',
                    'QBW_B_PV_BM'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.QBW_B_PV)+"mm ")])]),_c('div',{staticClass:"col1jg2"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(2, 'q1', 'KHG', 'q1_BM', '锅炉效率')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.IOG.QBW_A_PV, 'q1', 'KHG', 'q1_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.KHG.q1)+" ")]),_c('div',{staticClass:"fl widthxx",staticStyle:{"margin-left":"0.8vw"},style:({
                  background: !_vm.infoList.MKG.QBW_SEL == 3 ? '#2AFC30' : 'red',
                }),on:{"click":function($event){return _vm.toIpt('3', '水位3', 'QBW_SEL', 'MKG', 'true')}}},[_vm._v(" 水位3 ")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'QBW_C_PV',
                    'IOG',
                    'QBW_C_PV_BM',
                    '汽包水位过程值3'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.IOG.QBW_A_PV,
                    'QBW_C_PV',
                    'IOG',
                    'QBW_C_PV_BM'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.QBW_C_PV)+"mm ")])])]),_c('div',{staticClass:"control-model control-jg4"},[_c('div',{staticStyle:{"height":"3vh","line-height":"3vh"}},[_c('div',{staticClass:"control-col1 fl color1",on:{"click":function($event){return _vm.toCompon(8)}}},[_vm._v(" 控制模式 ")]),_c('div',{staticClass:"control-col1x fl color3",style:({
                  background: !_vm.infoList.IOG.MGM_SE ? '#2AFC30' : 'red',
                })},[_vm._v(" "+_vm._s(_vm.infoList.IOG.MGM_SEL ? "母管模式" : "单炉模式")+" ")])]),_c('div',{staticClass:"control-col2"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(2, 'ZQP_SP', 'IOG', 'ZQP_SP_BM', '主汽压力控制点')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.IOG.ZQP_SP, 'ZQP_SP', 'IOG', 'ZQP_SP_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.ZQP_SP)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("MPa")]),_c('div',{staticClass:"bottonx fl control-jg",style:({
                  background: !_vm.infoList.RQM__p__ZQP_QK1.TS
                    ? '#2AFC30'
                    : 'red',
                }),on:{"click":function($event){_vm.infoList.RQM__p__ZQP_QK1
                    ? _vm.toDetail(
                        2,
                        'ZQP_QK1',
                        'RQM__p__ZQP_QK1',
                        '',
                        '负荷前馈给煤'
                      )
                    : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"bottonx fl control-jg1",style:({
                  background: !_vm.infoList.RQM__p__ZQP_QK2.TS
                    ? '#2AFC30'
                    : 'red',
                }),on:{"click":function($event){_vm.infoList.RQM__p__ZQP_QK2
                    ? _vm.toDetail(
                        2,
                        'ZQP_QK2',
                        'RQM__p__ZQP_QK2',
                        '',
                        '管网负荷前馈'
                      )
                    : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"bottonx fl control-jg1",style:({
                  background: !_vm.infoList.RQM__p__ZQP_QK3.TS
                    ? '#2AFC30'
                    : 'red',
                }),on:{"click":function($event){_vm.infoList.RQM__p__ZQP_QK3
                    ? _vm.toDetail(
                        2,
                        'ZQP_QK3',
                        'RQM__p__ZQP_QK3',
                        '',
                        '燃料气前馈'
                      )
                    : ''}}},[_vm._v(" Q ")])]),_c('div',{staticClass:"control-col2"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(2, 'ZQP_PV', 'MKG', 'ZQP_PV_BM', '主汽压力测量值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.MKG.ZQP_PV, 'ZQP_PV', 'MKG', 'ZQP_PV_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.ZQP_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("MPa")]),_c('div',{staticClass:"bottonx fl control-jg",style:({
                  background: !_vm.infoList.XK__p__ZQP_XK2.RM ? '#2AFC30' : 'red',
                }),on:{"click":function($event){_vm.infoList.XK__p__ZQP_XK2
                    ? _vm.toDetail(
                        1,
                        'ZQP_XK2',
                        'XK__p__ZQP_XK2',
                        '',
                        '母管速率动态调节'
                      )
                    : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl control-jg1",style:({
                  background: !_vm.infoList.XK__p__ZQP_XK1.RM ? '#2AFC30' : 'red',
                }),on:{"click":function($event){_vm.infoList.XK__p__ZQP_XK1
                    ? _vm.toDetail(
                        1,
                        'ZQP_XK1',
                        'XK__p__ZQP_XK1',
                        '',
                        '主汽压力静态调节'
                      )
                    : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl control-jg1",style:({
                  background: !_vm.infoList.XK__p__GMJ_XK1.RM ? '#2AFC30' : 'red',
                }),on:{"click":function($event){_vm.infoList.XK__p__ZQF_XK1
                    ? _vm.toDetail(
                        1,
                        'ZQF_XK1',
                        'XK__p__ZQF_XK1',
                        '',
                        '主汽流量先控调节'
                      )
                    : ''}}},[_vm._v(" X ")])]),_c('div',{staticClass:"control-col2"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(2, 'ZQF_PV', 'IOG', 'ZQF_PV_BM', '蒸汽流量')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.IOG.ZQF_PV, 'ZQF_PV', 'IOG', 'ZQF_PV_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.ZQF_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")]),_c('div',{staticClass:"fl valuex control-jg2",on:{"click":function($event){return _vm.toCompon(2, 'ZQP_MV', 'MKG', 'ZQP_MV_BM', '给煤层操指令')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.MKG.ZQP_MV, 'ZQP_MV', 'MKG', 'ZQF_PV_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.ZQP_MV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])]),_c('div',{staticClass:"control-col2"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(2, 'MGF_SP', 'IOG', 'MGF_SP_BM', '母管分配负荷量')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.IOG.MGF_SP, 'MGF_SP', 'IOG', 'MGF_SP_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.MGF_SP)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")]),_c('div',{staticClass:"fl valuex control-jg2",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'MGP_SL',
                    'IOG',
                    'MGP_SL_SM',
                    '母管压力速率变化'
                  )},"dblclick":function($event){return _vm.Cclick(_vm.infoList.IOG.MGP_SL, 'MGP_SL', 'IOG', 'MGP_SL_SM')}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.MGP_SL)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])])]),_c('div',{staticClass:"molgd"},[_c('div',{staticClass:"molgd-col1"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'GML_E_PV',
                    'IOG',
                    'GML_E_PV_BM',
                    '5#给煤量过程值'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.IOG.GML_E_PV,
                    'GML_E_PV',
                    'IOG',
                    'GML_E_PV_BM'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.GML_E_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])]),_c('div',{staticClass:"molgd-col1 molgd-jg"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'GML_D_PV',
                    'IOG',
                    'GML_D_PV_BM',
                    '4#给煤量过程值'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.IOG.GML_D_PV,
                    'GML_D_PV',
                    'IOG',
                    'GML_D_PV_BM'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.GML_D_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])]),_c('div',{staticClass:"molgd-col1 molgd-jg"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'GML_C_PV',
                    'IOG',
                    'GML_C_PV_BM',
                    '3#给煤量过程值'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.IOG.GML_C_PV,
                    'GML_C_PV',
                    'IOG',
                    'GML_C_PV_BM'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.GML_C_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])]),_c('div',{staticClass:"molgd-col1 molgd-jg"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'GML_B_PV',
                    'IOG',
                    'GML_B_PV_BM',
                    '2#给煤量过程值'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.IOG.GML_B_PV,
                    'GML_B_PV',
                    'IOG',
                    'GML_B_PV_BM'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.GML_B_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])]),_c('div',{staticClass:"molgd-col1 molgd-jg"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'GML_A_PV',
                    'IOG',
                    'GML_A_PV_BM',
                    '1#给煤量过程值'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.IOG.GML_A_PV,
                    'GML_A_PV',
                    'IOG',
                    'GML_A_PV_BM'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.GML_A_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])])])]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"qbmain"},[_c('div',{staticClass:"fl qb-col2",on:{"click":function($event){return _vm.toCompon(2, 'QBW_PV', 'MKG', 'QBW_PV_BM', '水位过程值')}}},[_c('dv-water-level-pond',{staticStyle:{"width":"110px","height":"110px"},attrs:{"config":_vm.config}})],1)]),_c('div',{staticClass:"lutang"},[_c('div',{staticClass:"lutang-col1"},[_c('div',{staticClass:"fl widthx1"},[_vm._v("负压 SP")]),_c('div',{staticClass:"fl valuex color2 lutang-jg2",on:{"click":function($event){return _vm.toCompon(2, 'LTP_SP', 'IOG', 'LTP_SP_BM', '炉膛压力设定值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.IOG.LTP_SP, 'LTP_SP', 'IOG', 'LTP_SP_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.LTP_SP)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("Pa")])]),_c('div',{staticClass:"lutang-col1 lutang-jg1"},[_c('div',{staticClass:"fl valuex fu-color",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'LTP_A_PV',
                    'IOG',
                    'LTP_A_PV_BM',
                    '炉膛压力过程值1'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.IOG.LTP_A_PV,
                    'LTP_A_PV',
                    'IOG',
                    'LTP_A_PV_BM'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.LTP_A_PV)+" ")]),_c('div',{staticClass:"fl danwei fu-color"},[_vm._v("Pa")]),_c('div',{staticClass:"fl valuex fu-color lutang-jg",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'LTP_B_PV',
                    'IOG',
                    'LTP_B_PV_BM',
                    '炉膛压力过程值2'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.IOG.LTP_B_PV,
                    'LTP_B_PV',
                    'IOG',
                    'LTP_B_PV_BM'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.LTP_B_PV)+" ")]),_c('div',{staticClass:"fl danwei fu-color"},[_vm._v("Pa")])]),_c('div',{staticClass:"lutang-col1 lutang-jg1"},[_c('div',{staticClass:"fl widthx1",style:({
                  background: _vm.infoList.MKG.LTP_SEL == 1 ? '#2AFC30' : 'red',
                }),on:{"click":function($event){return _vm.toIpt('1', '负压1', 'LTP_SEL', 'MKG', 'true')}}},[_vm._v(" 负压1 ")]),_c('div',{staticClass:"fl widthx1 lutang-jg",style:({
                  background: _vm.infoList.MKG.LTP_SEL == 2 ? '#2AFC30' : 'red',
                }),on:{"click":function($event){return _vm.toIpt('2', '负压2', 'LTP_SEL', 'MKG', 'true')}}},[_vm._v(" 负压2 ")])]),_c('div',{staticClass:"lutang-col1 lutang-jg1"},[_c('div',{staticClass:"fl valuex fu-color",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'LTP_C_PV',
                    'IOG',
                    'LTP_C_PV_BM',
                    '炉膛压力过程值3'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.IOG.LTP_C_PV,
                    'LTP_C_PV',
                    'IOG',
                    'LTP_C_PV_BM'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.LTP_C_PV)+" ")]),_c('div',{staticClass:"fl danwei fu-color"},[_vm._v("Pa")]),_c('div',{staticClass:"fl valuex fu-color lutang-jg",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'LTP_D_PV',
                    'IOG',
                    'LTP_D_PV_BM',
                    '炉膛压力过程值4'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.IOG.LTP_D_PV,
                    'LTP_D_PV',
                    'IOG',
                    'LTP_D_PV_BM'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.LTP_D_PV)+" ")]),_c('div',{staticClass:"fl danwei fu-color"},[_vm._v("Pa")])]),_c('div',{staticClass:"lutang-col1 lutang-jg1"},[_c('div',{staticClass:"fl widthx1",style:({
                  background: _vm.infoList.MKG.LTP_SEL == 3 ? '#2AFC30' : 'red',
                }),on:{"click":function($event){return _vm.toIpt('3', '负压3', 'LTP_SEL', 'MKG', 'true')}}},[_vm._v(" 负压3 ")]),_c('div',{staticClass:"fl widthx1 lutang-jg",style:({
                  background: _vm.infoList.MKG.LTP_SEL == 4 ? '#2AFC30' : 'red',
                }),on:{"click":function($event){return _vm.toIpt('4', '负压4', 'LTP_SEL', 'MKG', 'true')}}},[_vm._v(" 负压4 ")])])])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"glqmain"},[_c('div',{staticClass:"glqtop flex"},[_c('div',{staticClass:"pingbi glqtop-jg"},[_c('div',{staticStyle:{"height":"2.5vh"}},[_c('div',{staticClass:"fl valuex lutang-jg2",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'PGCKT_A_PV',
                        'IOG',
                        'PGCKT_A_PV_BM',
                        '屏过出口烟气温度左'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.PGCKT_A_PV,
                        'PGCKT_A_PV',
                        'IOG',
                        'PGCKT_A_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.PGCKT_A_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])]),_c('div',[_c('div',{staticClass:"fl valuex lutang-jg2",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'PGCKT_B_PV',
                        'IOG',
                        'PGCKT_B_PV_BM',
                        '屏过出口烟气温度右'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.PGCKT_B_PV,
                        'PGCKT_B_PV',
                        'IOG',
                        'PGCKT_B_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.PGCKT_B_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])])]),_c('div',{staticClass:"gaowen glqtop-jg1"},[_c('div',{staticStyle:{"height":"2.5vh"}},[_c('div',{staticClass:"fl valuex lutang-jg2",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'GGRQT_A_PV',
                        'IOG',
                        'GGRQT_A_PV_BM',
                        '高过出口烟气温度左'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.GGRQT_A_PV,
                        'GGRQT_A_PV',
                        'IOG',
                        'GGRQT_A_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.GGRQT_A_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])]),_c('div',{staticClass:"heighthx"},[_c('div',{staticClass:"fl valuex lutang-jg2",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'GGRQT_B_PV',
                        'IOG',
                        'GGRQT_B_PV_BM',
                        '高过出口烟气温度右'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.GGRQT_B_PV,
                        'GGRQT_B_PV',
                        'IOG',
                        'GGRQT_B_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.GGRQT_B_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])])]),_c('div',{staticClass:"diwen glqtop-jg2"},[_c('div',{staticStyle:{"height":"2.5vh"}},[_c('div',{staticClass:"fl valuex lutang-jg2",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'DGRQT_A_PV',
                        'IOG',
                        'DGRQT_A_PV_BM',
                        '低过出口烟气温度左'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.GGRQT_A_PV,
                        'DGRQT_A_PV',
                        'IOG',
                        'DGRQT_A_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.DGRQT_A_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])]),_c('div',[_c('div',{staticClass:"fl valuex lutang-jg2",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'DGRQT_B_PV',
                        'IOG',
                        'DGRQT_B_PV_BM',
                        '低过出口烟气温度右'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.DGRQT_B_PV,
                        'DGRQT_B_PV',
                        'IOG',
                        'DGRQT_B_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.DGRQT_B_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])])])]),_c('div',{staticClass:"yangliang"},[_c('div',{staticStyle:{"height":"3vh"}},[_c('div',{staticClass:"fl widthx1"},[_vm._v("氧量SP")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'YQO_SP',
                      'IOG',
                      'YQO_SP_BM',
                      '烟气氧量设定值'
                    )},"dblclick":function($event){return _vm.Cclick(_vm.infoList.IOG.YQO_SP, 'YQO_SP', 'IOG', 'YQO_SP_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.YQO_SP)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("%")])]),_c('div',{staticStyle:{"height":"3vh"}},[_c('div',{staticClass:"fl widthx1",staticStyle:{"cursor":"pointer"}},[_vm._v("氧量1")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'YQO_A_PV',
                      'IOG',
                      'YQO_A_PV_BM',
                      '烟气氧量过程值1'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.YQO_A_PV,
                      'YQO_A_PV',
                      'IOG',
                      'YQO_A_PV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.YQO_A_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("%")])]),_c('div',{staticStyle:{"height":"3vh"}},[_c('div',{staticClass:"fl widthx1",staticStyle:{"cursor":"pointer"}},[_vm._v("氧量2")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'YQO_B_PV',
                      'IOG',
                      'YQO_B_PV_BM',
                      '烟气氧量过程值2'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.YQO_B_PV,
                      'YQO_B_PV',
                      'IOG',
                      'YQO_B_PV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.YQO_B_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("%")])])]),_c('div',{staticClass:"lutangall flex"},[_c('div',{staticClass:"ltbottom"},[_c('div',{staticClass:"ltbottom-row1"},[_c('div',{staticClass:"fl valuex12 fu-color",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'RQYL1_PV',
                        'IOG',
                        'RQYL1_PV_BM',
                        '阀前压力'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.RQYL1_PV,
                        'RQYL1_PV',
                        'IOG',
                        'RQYL1_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.RQYL1_PV)+" ")])]),_c('div',{staticClass:"ltbottom-row2"},[_c('div',{staticClass:"fl valuex12 fu-color",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'RQYL2_PV',
                        'IOG',
                        'RQYL2_PV_BM',
                        '阀后压力'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.RQYL2_PV,
                        'RQYL2_PV',
                        'IOG',
                        'RQYL2_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.RQYL2_PV)+" ")])]),_c('div',{staticClass:"ltbottom-row3"},[_c('div',{staticClass:"fl widthx2"},[_vm._v("燃气流量")]),_c('div',{staticClass:"fl valuex12 fu-color",on:{"click":function($event){return _vm.toCompon(2, 'RQLL_PV', 'IOG', 'RQLL_PV_BM', '燃气流量')},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.RQLL_PV,
                        'RQLL_PV',
                        'IOG',
                        'RQLL_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.RQLL_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("Nm³/h")])]),_c('div',{staticClass:"ltbottom-row3 ltbottm-jg"},[_c('div',{staticClass:"fl widthx2"},[_vm._v("总给煤量SP")]),_c('div',{staticClass:"fl valuex12 color2",on:{"click":function($event){return _vm.toCompon(2, 'GML_SP', 'MKG', 'GML_SP_BM', '总给煤指令')},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.MKG.GML_SP,
                        'GML_SP',
                        'MKG',
                        'GML_SP_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.GML_SP)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])]),_c('div',{staticClass:"ltbottom-row3"},[_c('div',{staticClass:"fl widthx2"},[_vm._v("总给煤量PV")]),_c('div',{staticClass:"fl valuex12",on:{"click":function($event){return _vm.toCompon(2, 'GML_PV', 'IOG', 'GML_PV_BM', '总给煤量')},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.GML_PV,
                        'GML_PV',
                        'IOG',
                        'GML_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.GML_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])]),_c('div',{staticClass:"ltbottom-row3"},[_c('div',{staticClass:"fl widthx2"},[_vm._v("总风量SP")]),_c('div',{staticClass:"fl valuex12",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'SFF_SP',
                        'IOG',
                        'SFF_SP_BM',
                        '送风流量设定值'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.SFF_SP,
                        'SFF_SP',
                        'IOG',
                        'SFF_SP_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.SFF_SP)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])]),_c('div',{staticClass:"ltbottom-row3"},[_c('div',{staticClass:"fl widthx2"},[_vm._v("总风量PV")]),_c('div',{staticClass:"fl valuex12",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'SFF_PV',
                        'MKG',
                        'SFF_PV_BM',
                        '送风量过程值'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.MKG.SFF_PV,
                        'SFF_PV',
                        'MKG',
                        'SFF_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.SFF_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])]),_c('div',[_c('div',{staticClass:"ltbottom-row4 fl"},[_c('div',{staticClass:"bottonx fl ltbottm-jg2",style:({
                        background:
                          _vm.infoList.RQM__p__ECF_MAN4.RM == 1 ||
                          _vm.infoList.RQM__p__ECF_MAN5.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANECFS', 2)}}},[_vm._v(" A ")]),_c('div',{staticClass:"fl widthx2"},[_vm._v("一次风压SP")]),_c('div',{staticClass:"fl valuex12",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'YCF_A_SP',
                          'IOG',
                          'YCF_A_SP_BM',
                          '1#一次风设定值'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.IOG.YCF_A_SP,
                          'SFF_PV',
                          'IOG',
                          'YCF_A_SP_BM'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.YCF_A_SP)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("KPa")])])]),_c('div',{staticClass:"ltbottom-row3 ltbottm-jg3"},[_c('div',{staticClass:"fl widthx2"},[_vm._v("再热温度SP")]),_c('div',{staticClass:"fl valuex12",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'ZRQT_A_SP',
                        'IOG',
                        'ZRQT_A_SP_BM',
                        '甲再热汽温设定值'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.ZRQT_A_SP,
                        'ZRQT_A_SP',
                        'IOG',
                        'ZRQT_A_SP_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.ZRQT_A_SP)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])]),_c('div',{staticClass:"ltbottom-row3"},[_c('div',{staticClass:"fl widthx2"},[_vm._v("再热温度PV")]),_c('div',{staticClass:"fl valuex12",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'ZRQT_A_PV',
                        'IOG',
                        'ZRQT_A_PV_BM',
                        '甲再热汽温过程值'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.ZRQT_A_PV,
                        'ZRQT_A_PV',
                        'IOG',
                        'ZRQT_A_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.ZRQT_A_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])]),_c('div',{staticClass:"ltbottom-row3"},[_c('div',{staticClass:"fl widthx2"},[_vm._v("再热流量SP")]),_c('div',{staticClass:"fl valuex12",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'ZRQT_B_SPCL',
                        'IOG',
                        'ZRQT_B_SPCL_BM',
                        '再热器流量计算设定值'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.ZRQT_B_SPCL,
                        'ZRQT_B_SPCL',
                        'IOG',
                        'ZRQT_B_SPCL_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.ZRQT_B_SPCL)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])]),_c('div',[_c('div',{staticClass:"ltbottom-row4 fl"},[_c('div',{staticClass:"bottonx fl ltbottm-jg2",style:({
                        background:
                          _vm.infoList.RQM__p__ECF_MAN1.RM == 1 ||
                          _vm.infoList.RQM__p__ECF_MAN2.RM == 1 ||
                          _vm.infoList.RQM__p__ECF_MAN3.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANECFX', 3)}}},[_vm._v(" A ")]),_c('div',{staticClass:"fl widthx2"},[_vm._v("再热流量PV")]),_c('div',{staticClass:"fl valuex12",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'ZRQT_B_PV',
                          'IOG',
                          'ZRQT_B_PV_BM',
                          '乙再热汽温过程值'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.IOG.ZRQT_B_PV,
                          'ZRQT_B_PV',
                          'IOG',
                          'ZRQT_B_PV_BM'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.ZRQT_B_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])])])]),_c('div',{staticClass:"shengmq"},[_c('div',{staticClass:"shengmq-row1"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'SMQT_1A_PV',
                        'IOG',
                        'SMQT_1A_PV_BM',
                        '一级省煤器烟气温度左'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.SMQT_1A_PV,
                        'SMQT_1A_PV',
                        'IOG',
                        'SMQT_1A_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.SMQT_1A_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")]),_c('div',{staticClass:"fl valuex shengmq-jg",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'SMQT_1B_PV',
                        'IOG',
                        'SMQT_1B_PV_BM',
                        '一级省煤器烟气温度右'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.SMQT_1B_PV,
                        'SMQT_1B_PV',
                        'IOG',
                        'SMQT_1B_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.SMQT_1B_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])]),_c('div',{staticClass:"shengmq-row1 shengmq-jg1"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'SMQT_2A_PV',
                        'IOG',
                        'SMQT_2A_PV_BM',
                        '二级省煤器烟气温度左'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.SMQT_2A_PV,
                        'SMQT_2A_PV',
                        'IOG',
                        'SMQT_2A_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.SMQT_2A_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")]),_c('div',{staticClass:"fl valuex shengmq-jg",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'SMQT_2B_PV',
                        'IOG',
                        'SMQT_2B_PV_BM',
                        '二级省煤器烟气温度右'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.SMQT_2B_PV,
                        'SMQT_2B_PV',
                        'IOG',
                        'SMQT_2B_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.SMQT_2B_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])]),_c('div',{staticClass:"shengmq-row1 shengmq-jg1"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'YRQT_1A_PV',
                        'IOG',
                        'YRQT_1A_PV_BM',
                        '一级预热器烟气温度左'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.YRQT_1A_PV,
                        'YRQT_1A_PV',
                        'IOG',
                        'YRQT_1A_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.YRQT_1A_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")]),_c('div',{staticClass:"fl valuex shengmq-jg",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'YRQT_1B_PV',
                        'IOG',
                        'YRQT_1B_PV_BM',
                        '一级预热器烟气温度右'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.YRQT_1B_PV,
                        'YRQT_1B_PV',
                        'IOG',
                        'YRQT_1B_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.YRQT_1B_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])]),_c('div',{staticClass:"shengmq-row1 shengmq-jg2"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'PYT_A_PV',
                        'IOG',
                        'PYT_A_PV_BM',
                        '排烟温度左'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.PYT_A_PV,
                        'PYT_A_PV',
                        'IOG',
                        'PYT_A_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.PYT_A_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")]),_c('div',{staticClass:"fl valuex shengmq-jg",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'PYT_B_PV',
                        'IOG',
                        'PYT_B_PV_BM',
                        '排烟温度右'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.PYT_B_PV,
                        'PYT_B_PV',
                        'IOG',
                        'PYT_B_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.PYT_B_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])])])])]),_c('div',{staticClass:"rightmain"},[_c('div',{staticClass:"geishui"},[_c('div',{staticClass:"rightmain-row1"},[_c('div',{staticClass:"rightmain-row1-col color1 rightmain-jg fl"},[_vm._v(" 减温后 ")]),_c('div',{staticClass:"rightmain-row1-col color1 rightmain-jg fl"},[_vm._v(" 开度 ")]),_c('div',{staticClass:"rightmain-row1-col color1 rightmain-jg fl"},[_vm._v(" 减温前 ")]),_c('div',{staticClass:"fl valuex rightmain-jg",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'ZQT_2A_PV',
                      'IOG',
                      'ZQT_2A_PV_BM',
                      '甲主汽温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.ZQT_2A_PV,
                      'ZQT_2A_PV',
                      'IOG',
                      'ZQT_2A_PV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.ZQT_2A_PV)+" ")]),_c('div',{staticClass:"fl danwei rightmain-jg"},[_vm._v("℃")]),_c('div',{staticClass:"fl valuex rightmain-jg",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'ZQT_2B_PV',
                      'IOG',
                      'ZQT_2B_PV_BM',
                      '乙主汽温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.ZQT_2B_PV,
                      'ZQT_2B_PV',
                      'IOG',
                      'ZQT_2B_PV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.ZQT_2B_PV)+" ")]),_c('div',{staticClass:"fl danwei rightmain-jg"},[_vm._v("℃")])]),_c('div',{staticClass:"rightmain-row1 rightmain-jg2 fl"},[_c('div',{staticClass:"Kclick",on:{"click":function($event){return _vm.toCompon(7)}}}),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'JWHT_2A_PV',
                      'IOG',
                      'JWHT_2A_PV_BM',
                      '甲二级减温后温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.JWHT_2A_PV,
                      'JWHT_2A_PV',
                      'IOG',
                      'JWHT_2A_PV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.JWHT_2A_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")]),_c('div',{staticClass:"fl valuex rightmain-jg2",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'ZQT_2A_FV',
                      'IOG',
                      'ZQT_2A_FV_BM',
                      '甲二级减温阀反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.ZQT_2A_FV,
                      'ZQT_2A_FV',
                      'IOG',
                      'ZQT_2A_FV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.ZQT_2A_FV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("%")]),_c('div',{staticClass:"fl valuex rightmain-jg3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'JWQT_2A_PV',
                      'IOG',
                      'JWQT_2A_PV_BM',
                      '甲二级减温前温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.JWQT_2A_PV,
                      'JWQT_2A_PV',
                      'IOG',
                      'ZQT_2A_FV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.JWQT_2A_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")]),_c('div',{staticClass:"fl widthx3 color1 fl"},[_vm._v("二级")]),_c('div',{staticClass:"bottonx fl",style:({
                    background: !_vm.infoList.XK__p__ZQT_XK5.RM
                      ? '#2AFC30'
                      : 'red',
                  }),on:{"click":function($event){_vm.infoList.XK__p__ZQT_XK5
                      ? _vm.toDetail(
                          1,
                          'ZQT_XK5',
                          'XK__p__ZQT_XK5',
                          '',
                          '二级甲减温先控调节'
                        )
                      : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                    background: !_vm.infoList.RQM__p__ZQT_QK3.TS
                      ? '#2AFC30'
                      : 'red',
                  }),on:{"click":function($event){_vm.infoList.RQM__p__ZQT_QK3
                      ? _vm.toDetail(
                          2,
                          'ZQT_QK3',
                          'RQM__p__ZQT_QK3',
                          '',
                          '二级减温左前馈'
                        )
                      : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                    background: !_vm.infoList.RQM__p__ZQT_QK5.TS
                      ? '#2AFC30'
                      : 'red',
                  }),on:{"click":function($event){_vm.infoList.RQM__p__ZQT_QK5
                      ? _vm.toDetail(
                          2,
                          'ZQT_QK5',
                          'RQM__p__ZQT_QK5',
                          '',
                          '负荷前馈'
                        )
                      : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"fl widthx4 color1"},[_vm._v("左二级减温SP")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'ZQT_2A_SP',
                      'IOG',
                      'ZQT_2A_SP_BM',
                      '甲二级减温控制点'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.ZQT_2A_SP,
                      'ZQT_2A_SP',
                      'IOG',
                      'ZQT_2A_FV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.ZQT_2A_SP)+"℃ ")])]),_c('div',{staticClass:"rightmain-row1 rightmain-jg2 rightmain-jg5 fl"},[_c('div',{staticClass:"Kclick",on:{"click":function($event){return _vm.toCompon(7)}}}),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'JWHT_2B_PV',
                      'IOG',
                      'JWHT_2B_PV_BM',
                      '乙二级减温后温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.JWHT_2B_PV,
                      'JWHT_2B_PV',
                      'IOG',
                      'JWHT_2B_PV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.JWHT_2B_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")]),_c('div',{staticClass:"fl valuex rightmain-jg2",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'ZQT_2B_FV',
                      'IOG',
                      'ZQT_2B_FV_BM',
                      '乙二级减温阀反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.ZQT_2B_FV,
                      'ZQT_2B_FV',
                      'IOG',
                      'ZQT_2B_FV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.ZQT_2B_FV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("%")]),_c('div',{staticClass:"fl valuex rightmain-jg3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'JWQT_2B_PV',
                      'IOG',
                      'JWQT_2B_PV_BM',
                      '乙二级减温前温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.JWQT_2B_PV,
                      'JWQT_2B_PV',
                      'IOG',
                      'JWQT_2B_PV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.JWQT_2B_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")]),_c('div',{staticClass:"fl widthx3 color1 fl"}),_c('div',{staticClass:"bottonx fl",style:({
                    background: !_vm.infoList.XK__p__ZQT_XK7.RM
                      ? '#2AFC30'
                      : 'red',
                  }),on:{"click":function($event){_vm.infoList.XK__p__ZQT_XK7
                      ? _vm.toDetail(
                          1,
                          'ZQT_XK7',
                          'XK__p__ZQT_XK7',
                          '',
                          '二级乙减温先控调节'
                        )
                      : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                    background: !_vm.infoList.RQM__p__ZQT_QK4.TS
                      ? '#2AFC30'
                      : 'red',
                  }),on:{"click":function($event){_vm.infoList.RQM__p__ZQT_QK4
                      ? _vm.toDetail(
                          2,
                          'ZQT_QK4',
                          'RQM__p__ZQT_QK4',
                          '',
                          '二级减温右前馈'
                        )
                      : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"widthx31 fl buttonjg"}),_c('div',{staticClass:"fl widthx4 color1"},[_vm._v("右二级减温SP")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'ZQT_2B_SP',
                      'IOG',
                      'ZQT_2B_SP_BM',
                      '乙一级减温控制点'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.ZQT_2B_SP,
                      'ZQT_2B_SP',
                      'IOG',
                      'ZQT_2B_SP_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.ZQT_2B_SP)+"℃ ")])]),_c('div',{staticClass:"rightmain-row1 rightmain-jg2 rightmain-jg4 fl"},[_c('div',{staticClass:"Kclick",on:{"click":function($event){return _vm.toCompon(7)}}}),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'JWHT_1A_PV',
                      'IOG',
                      'JWHT_1A_PV_BM',
                      '甲一级减温后温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.JWHT_1A_PV,
                      'JWHT_1A_PV',
                      'IOG',
                      'JWHT_1A_PV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.JWHT_1A_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")]),_c('div',{staticClass:"fl valuex rightmain-jg2",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'ZQT_1A_FV',
                      'IOG',
                      'ZQT_1A_FV_BM',
                      '甲一级减温阀反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.ZQT_1A_FV,
                      'ZQT_1A_FV',
                      'IOG',
                      'ZQT_1A_FV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.ZQT_1A_FV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("%")]),_c('div',{staticClass:"fl valuex rightmain-jg3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'JWQT_1A_PV',
                      'IOG',
                      'JWQT_1A_PV_BM',
                      '甲一级减温前温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.JWQT_1A_PV,
                      'JWQT_1A_PV',
                      'IOG',
                      'JWQT_1A_PV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.JWQT_1A_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")]),_c('div',{staticClass:"fl widthx3 color1 fl"},[_vm._v("一级")]),_c('div',{staticClass:"bottonx fl",style:({
                    background: !_vm.infoList.XK__p__ZQT_XK1.RM
                      ? '#2AFC30'
                      : 'red',
                  }),on:{"click":function($event){_vm.infoList.XK__p__ZQT_XK1
                      ? _vm.toDetail(
                          1,
                          'ZQT_XK1',
                          'XK__p__ZQT_XK1',
                          '',
                          '一级甲主汽温度'
                        )
                      : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                    background: !_vm.infoList.RQM__p__ZQT_QK1.TS
                      ? '#2AFC30'
                      : 'red',
                  }),on:{"click":function($event){_vm.infoList.RQM__p__ZQT_QK1
                      ? _vm.toDetail(
                          2,
                          'ZQT_QK1',
                          'RQM__p__ZQT_QK1',
                          '',
                          '一级减温左前馈'
                        )
                      : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"widthx31 fl buttonjg"}),_c('div',{staticClass:"fl widthx4 color1"},[_vm._v("左一级减温SP")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'ZQT_1A_SP',
                      'IOG',
                      'ZQT_1A_SP_BM',
                      '甲一级减温控制点'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.ZQT_1A_SP,
                      'ZQT_1A_SP',
                      'IOG',
                      'ZQT_1A_SP_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.ZQT_1A_SP)+"℃ ")])]),_c('div',{staticClass:"rightmain-row1 rightmain-jg2 rightmain-jg4 fl"},[_c('div',{staticClass:"Kclick",on:{"click":function($event){return _vm.toCompon(7)}}}),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'JWHT_1B_PV',
                      'IOG',
                      'JWHT_1B_PV_BM',
                      '乙一级减温后温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.JWHT_1B_PV,
                      'JWHT_1B_PV',
                      'IOG',
                      'JWHT_1B_PV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.JWHT_1B_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")]),_c('div',{staticClass:"fl valuex rightmain-jg2",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'ZQT_1B_FV',
                      'IOG',
                      'ZQT_1B_FV_BM',
                      '乙一级减温阀反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.ZQT_1B_FV,
                      'ZQT_1B_FV',
                      'IOG',
                      'ZQT_1B_FV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.ZQT_1B_FV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("%")]),_c('div',{staticClass:"fl valuex rightmain-jg3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'JWQT_1B_PV',
                      'IOG',
                      'JWQT_1B_PV_BM',
                      '乙一级减温前温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.JWQT_1B_PV,
                      'JWQT_1B_PV',
                      'IOG',
                      'JWQT_1B_PV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.JWQT_1B_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")]),_c('div',{staticClass:"fl widthx3 color1 fl"}),_c('div',{staticClass:"bottonx fl",style:({
                    background: !_vm.infoList.XK__p__ZQT_XK3.RM
                      ? '#2AFC30'
                      : 'red',
                  }),on:{"click":function($event){_vm.infoList.XK__p__ZQT_XK3
                      ? _vm.toDetail(
                          1,
                          'ZQT_XK3',
                          'XK__p__ZQT_XK3',
                          '',
                          '一级乙主汽温度'
                        )
                      : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                    background: !_vm.infoList.RQM__p__ZQT_QK2.TS
                      ? '#2AFC30'
                      : 'red',
                  }),on:{"click":function($event){_vm.infoList.RQM__p__ZQT_QK2
                      ? _vm.toDetail(
                          2,
                          'ZQT_QK2',
                          'RQM__p__ZQT_QK2',
                          '',
                          '一级减温右前馈'
                        )
                      : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                    background:
                      _vm.infoList.RQM__p__ZQT_MAN1.RM == 1 ||
                      _vm.infoList.RQM__p__ZQT_MAN2.RM == 1 ||
                      _vm.infoList.RQM__p__ZQT_MAN3.RM == 1 ||
                      _vm.infoList.RQM__p__ZQT_MAN4.RM == 1
                        ? '#2AFC30'
                        : 'red',
                  }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANQW', 4)}}},[_vm._v(" A ")]),_c('div',{staticClass:"fl widthx4 color1"},[_vm._v("右一级减温SP")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'ZQT_1B_SP',
                      'IOG',
                      'ZQT_1B_SP_BM',
                      '乙一级减温控制点'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.ZQT_1B_SP,
                      'ZQT_1B_SP',
                      'IOG',
                      'ZQT_1B_SP'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.ZQT_1B_SP)+"℃ ")])]),_c('div',{staticClass:"rightmain-row1 rightmain-jg2 rightmain-jg4 fl"},[_c('div',{staticClass:"Kclick Kclick1",on:{"click":function($event){return _vm.toCompon(9)}}}),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(2, 'GST_PV', 'IOG', 'GST_PV_BM', '给水温度')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.IOG.GST_PV, 'GST_PV', 'IOG', 'GST_PV_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.GST_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])]),_c('div',{staticClass:"rightmain-row1 rightmain-jg2 fl"},[_c('div',{staticClass:"Kclick Kclick2",on:{"click":function($event){return _vm.toCompon(9)}}}),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'GSP_B_PV',
                      'IOG',
                      'GSP_B_PV_BM',
                      '2#给水压力'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.GSP_B_PV,
                      'GSP_B_PV',
                      'IOG',
                      'GSP_B_PV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.GSP_B_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("MPa")]),_c('div',{staticClass:"fl valuex rightmain-jg6",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'QBW_A_FV',
                      'IOG',
                      'QBW_A_FV_BM',
                      '主给水阀门反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.QBW_A_FV,
                      'QBW_A_FV',
                      'IOG',
                      'GSP_B_PV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.QBW_A_FV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("%")]),_c('div',{staticClass:"fl widthx1 color1 fl rightmain-jg7"},[_vm._v("主给水")])]),_c('div',[_c('div',{staticClass:"rightmain-row1 rightmain-jg2 fl"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(2, 'GSF_PV', 'IOG', 'GSF_PV_BM', '给水流量')},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.GSF_PV,
                        'GSF_PV',
                        'IOG',
                        'GSP_B_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.GSF_PV)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])]),_c('div',{staticClass:"rightmain-row1 rightmain-jg8 fl"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'QBW_B_FV',
                        'IOG',
                        'QBW_B_FV_BM',
                        '副给水阀门反馈'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.QBW_B_FV,
                        'QBW_B_FV',
                        'IOG',
                        'QBW_B_FV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.QBW_B_FV)+" ")]),_c('div',{staticClass:"fl danwei rightmain-jg31"},[_vm._v("℃")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                      background: !_vm.infoList.XK__p__QBW_XK1.RM
                        ? '#2AFC30'
                        : 'red',
                    }),on:{"click":function($event){_vm.infoList.XK__p__QBW_XK1
                        ? _vm.toDetail(
                            1,
                            'QBW_XK1',
                            'XK__p__QBW_XK1',
                            '',
                            '汽包水位主调节先控'
                          )
                        : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                      background: !_vm.infoList.XK__p__QBW_XK2.RM
                        ? '#2AFC30'
                        : 'red',
                    }),on:{"click":function($event){_vm.infoList.XK__p__QBW_XK2
                        ? _vm.toDetail(
                            1,
                            'QBW_XK2',
                            'XK__p__QBW_XK2',
                            '',
                            '主给水副调节'
                          )
                        : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                      background: !_vm.infoList.XK__p__QBW_XK3.RM
                        ? '#2AFC30'
                        : 'red',
                    }),on:{"click":function($event){_vm.infoList.XK__p__QBW_XK3
                        ? _vm.toDetail(
                            1,
                            'QBW_XK3',
                            'XK__p__QBW_XK3',
                            '',
                            '副给水副调节'
                          )
                        : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                      background:
                        _vm.infoList.RQM__p__QBW_MAN1.RM == 1 ||
                        _vm.infoList.RQM__p__QBW_MAN2.RM == 1
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANGS', 2)}}},[_vm._v(" A ")])])])]),_c('div',{staticClass:"songfeng"},[_c('div',{staticClass:"fl yinfen-jg"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'SFF_B_PV',
                      'IOG',
                      'SFF_B_PV_BM',
                      '送风流量过程值2'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.SFF_B_PV,
                      'SFF_B_PV',
                      'IOG',
                      'SFF_B_PV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.SFF_B_PV)+"t/h ")])]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"yinfen-jg2",staticStyle:{"margin-left":"7.7vw"}},[_c('div',{staticClass:"Kclick Kclick3",on:{"click":function($event){return _vm.toCompon(10)}}}),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'SFI_A_PV',
                        'IOG',
                        'SFI_A_PV_BM',
                        '1#送风机电流'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.SFI_A_PV,
                        'SFI_A_PV',
                        'IOG',
                        'SFF_B_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.SFI_A_PV)+"A ")]),_c('div',{staticClass:"fl widthx5 color1",staticStyle:{"margin-top":"-2vh"}},[_vm._v(" 送风机1# ")])]),_c('div',{staticClass:"yinfen-jg2",staticStyle:{"margin-left":"7.7vw","margin-top":"9vh"}},[_c('div',{staticClass:"Kclick Kclick3",on:{"click":function($event){return _vm.toCompon(10)}}}),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'SFI_B_PV',
                        'IOG',
                        'SFI_B_PV_BM',
                        '2#送风机电流'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.SFI_B_PV,
                        'SFI_B_PV',
                        'IOG',
                        'SFI_B_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.SFI_B_PV)+"A ")]),_c('div',{staticClass:"fl widthx5 color1",staticStyle:{"margin-top":"-2vh"}},[_vm._v(" 送风机2# ")])])]),_c('div',{staticClass:"fl yinfen-jg4"},[_c('div',[_c('div',{staticClass:"valuexzr",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'YQO_BA_MV',
                        'IOG',
                        'YQO_BA_MV_BM',
                        '1#送风挡板XK输出'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.YQO_BA_MV,
                        'YQO_BA_MV',
                        'IOG',
                        'YQO_BA_MV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.YQO_BA_MV)+"% ")]),_c('div',{staticClass:"valuexzr",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'YQO_BA_FV',
                        'IOG',
                        'YQO_BA_FV_BM',
                        '1#送风反馈'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.YQO_BA_FV,
                        'YQO_BA_FV',
                        'IOG',
                        'YQO_BA_FV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.YQO_BA_FV)+"% ")])]),_c('div',{staticClass:"yinfen-jg5"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"valuexzr",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'YQO_BB_MV',
                          'IOG',
                          'YQO_BB_MV_BM',
                          '2#送风挡板XK输出'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.IOG.YQO_BB_MV,
                          'YQO_BB_MV',
                          'IOG',
                          'YQO_BB_MV_BM'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.YQO_BB_MV)+"% ")]),_c('div',{staticClass:"valuexzr",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'YQO_BB_FV',
                          'IOG',
                          'YQO_BB_FV_BM',
                          '2#送风反馈'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.IOG.YQO_BB_FV,
                          'YQO_BB_FV',
                          'IOG',
                          'YQO_BB_FV_BM'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.YQO_BB_FV)+"% ")])]),_c('div',{staticClass:"fl yinfen-jg6"},[_c('div',{staticClass:"bottonx fl buttonjg",style:({
                        background: !_vm.infoList.RQM__p__YQO_RSF1.TS
                          ? '#2AFC30'
                          : 'red',
                      }),on:{"click":function($event){_vm.infoList.RQM__p__YQO_RSF1
                          ? _vm.toDetail(
                              3,
                              'YQO_RSF1',
                              'RQM__p__YQO_RSF1',
                              '',
                              '送风机电流纠偏'
                            )
                          : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                        background: !_vm.infoList.XK__p__YQO_XK1.RM
                          ? '#2AFC30'
                          : 'red',
                      }),on:{"click":function($event){_vm.infoList.XK__p__YQO_XK1
                          ? _vm.toDetail(
                              1,
                              'YQO_XK1',
                              'XK__p__YQO_XK1',
                              '',
                              '氧量先控调节'
                            )
                          : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                        background: !_vm.infoList.XK__p__YQO_XK2.RM
                          ? '#2AFC30'
                          : 'red',
                      }),on:{"click":function($event){_vm.infoList.XK__p__YQO_XK2
                          ? _vm.toDetail(
                              1,
                              'YQO_XK2',
                              'XK__p__YQO_XK2',
                              '',
                              '送风变频先控调节'
                            )
                          : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                        background:
                          _vm.infoList.RQM__p__YQO_MAN1.RM == 1 ||
                          _vm.infoList.RQM__p__YQO_MAN2.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANSF', 2)}}},[_vm._v(" A ")])])])])]),_c('div',{staticClass:"yicfeng"},[_c('div',{staticClass:"Kclick Kclick4",on:{"click":function($event){return _vm.toCompon(11)}}}),_c('div',{staticClass:"Kclick Kclick5",on:{"click":function($event){return _vm.toCompon(11)}}}),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"yinfen-jg2",staticStyle:{"margin-left":"4.7vw"}},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'YCF_A_PV',
                        'IOG',
                        'YCF_A_PV_BM',
                        '1#一次风过程值'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.YCF_A_PV,
                        'YCF_A_PV',
                        'IOG',
                        'YCF_A_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.YCF_A_PV)+"KPa ")]),_c('div',{staticClass:"fl widthx5 color1"},[_vm._v("一次风机1")])]),_c('div',{staticClass:"yinfen-jg2",staticStyle:{"margin-left":"4.7vw","margin-top":"9vh"}},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'YCF_B_PV',
                        'IOG',
                        'YCF_B_PV_BM',
                        '2#一次风过程值'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.YCF_B_PV,
                        'YCF_B_PV',
                        'IOG',
                        'YCF_B_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.YCF_B_PV)+"KPa ")]),_c('div',{staticClass:"fl widthx5 color1"},[_vm._v("一次风机2")])])]),_c('div',{staticClass:"fl yinfen-jg4"},[_c('div',[_c('div',{staticClass:"valuex yinfen-jg7",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'SFI_A_PV',
                        'IOG',
                        'SFI_A_PV_BM',
                        '1#送风机电流'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.SFI_A_PV,
                        'SFI_A_PV',
                        'IOG',
                        'SFI_A_PV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.SFI_A_PV)+"A ")])]),_c('div',{staticClass:"yinfen-jg5"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"valuex yinfen-jg7",staticStyle:{"margin-top":"4vh"},on:{"click":function($event){return _vm.toCompon(
                          2,
                          'SFI_B_PV',
                          'IOG',
                          'SFI_B_PV_BM',
                          '2#送风机电流'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.IOG.SFI_B_PV,
                          'SFI_B_PV',
                          'IOG',
                          'SFI_B_PV_BM'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.SFI_B_PV)+"A ")])])])]),_c('div',{staticClass:"fl yinfen-jg9"},[_c('div',{staticClass:"valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'YCF_A_MV',
                      'IOG',
                      'YCF_A_MV_BM',
                      '1#一次风XK输出'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.YCF_A_MV,
                      'YCF_A_MV',
                      'IOG',
                      'YCF_A_MV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.YCF_A_MV)+"% ")]),_c('div',{staticClass:"valuex yinfen-jg10",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'YCF_A_FV',
                      'IOG',
                      'YCF_A_FV_BM',
                      '1#一次风开度反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.YCF_A_FV,
                      'YCF_A_FV',
                      'IOG',
                      'YCF_A_FV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.YCF_A_FV)+"% ")]),_c('div',{staticClass:"valuex yinfen-jg2",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'YCF_B_MV',
                      'IOG',
                      'YCF_B_MV_BM',
                      '2#一次风XK输出'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.YCF_B_MV,
                      'YCF_B_MV',
                      'IOG',
                      'YCF_B_MV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.YCF_B_MV)+"% ")]),_c('div',{staticClass:"valuex yinfen-jg11",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'YCF_B_FV',
                      'IOG',
                      'YCF_B_FV_BM',
                      '2#一次风开度反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.YCF_B_FV,
                      'YCF_B_FV',
                      'IOG',
                      'YCF_B_FV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.YCF_B_FV)+"% ")])]),_c('div',{staticClass:"fl yinfen-jg8"},[_c('div',{staticClass:"bottonx fl buttonjg",style:({
                    background: !_vm.infoList.RQM__p__YCF_QK1.SP
                      ? '#2AFC30'
                      : 'red',
                  }),on:{"click":function($event){_vm.infoList.RQM__p__YCF_QK1
                      ? _vm.toDetail(
                          2,
                          'YCF_QK1',
                          'RQM__p__YCF_QK1',
                          '',
                          '一次风前馈'
                        )
                      : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                    background: !_vm.infoList.RQM__p__YCF_RSF1.TS
                      ? '#2AFC30'
                      : 'red',
                  }),on:{"click":function($event){_vm.infoList.RQM__p__YCF_RSF1
                      ? _vm.toDetail(
                          3,
                          'YCF_RSF1',
                          'RQM__p__YCF_RSF1',
                          '',
                          '一次风机电流纠偏'
                        )
                      : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                    background: !_vm.infoList.XK__p__YCF_XK1.RM
                      ? '#2AFC30'
                      : 'red',
                  }),on:{"click":function($event){_vm.infoList.XK__p__YCF_XK1
                      ? _vm.toDetail(
                          1,
                          'YCF_XK1',
                          'XK__p__YCF_XK1',
                          '',
                          '一次风压先控调节'
                        )
                      : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                    background:
                      _vm.infoList.RQM__p__YCF_MAN1.RM == 1 ||
                      _vm.infoList.RQM__p__YCF_MAN2.RM == 1
                        ? '#2AFC30'
                        : 'red',
                  }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANYCF', 2)}}},[_vm._v(" A ")])])]),_c('div',{staticClass:"yinfeng"},[_c('div',{staticClass:"Kclick Kclick6",on:{"click":function($event){return _vm.toCompon(12)}}}),_c('div',{staticClass:"Kclick Kclick7",on:{"click":function($event){return _vm.toCompon(12)}}}),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"yinfen-jg2",staticStyle:{"margin-left":"4vw"}},[_c('div',{staticClass:"fl valuexzr",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'LTP_DA_FV',
                        'IOG',
                        'LTP_DA_FV_BM',
                        '1#引风挡板反馈'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.LTP_DA_FV,
                        'LTP_DA_FV',
                        'IOG',
                        'LTP_DA_FV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.LTP_DA_FV)+"% ")])]),_c('div',{staticClass:"yinfen-jg2",staticStyle:{"margin-left":"4vw","margin-top":"13vh"}},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'LTP_DB_FV',
                        'IOG',
                        'LTP_DB_FV_BM',
                        '2#引风挡板反馈'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.IOG.LTP_DB_FV,
                        'LTP_DB_FV',
                        'IOG',
                        'LTP_DB_FV_BM'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.LTP_DB_FV)+"% ")])])]),_c('div',{staticClass:"fl yinfeng-jg2",staticStyle:{"margin-left":"1.8vw"}},[_c('div',{staticClass:"valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'LTP_BA_FV',
                      'IOG',
                      'LTP_BA_FV_BM',
                      '1#引风液耦反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.LTP_BA_FV,
                      'LTP_BA_FV',
                      'IOG',
                      'YCF_A_MV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.LTP_BA_FV)+"% ")]),_c('div',{staticClass:"valuex",staticStyle:{"margin-top":"0.5vh"},on:{"click":function($event){return _vm.toCompon(2, 'LTP_BA_MV', 'IOG', 'LTP_BA_MV_BM')},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.LTP_BA_MV,
                      'LTP_BA_MV',
                      'IOG',
                      'LTP_BA_MV_BM',
                      '1#引风液耦XK输出'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.LTP_BA_MV)+"% ")]),_c('div',{staticClass:"valuex yinfen-jg2 yinfeng-jg1",on:{"click":function($event){return _vm.toCompon(2, 'LTP_BB_FV', 'IOG', 'LTP_BB_FV_BM')},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.YCF_B_MV,
                      'LTP_BB_FV',
                      'IOG',
                      'LTP_BB_FV_BM',
                      '2#引风液耦反馈'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.LTP_BB_FV)+"% ")]),_c('div',{staticClass:"valuex",staticStyle:{"margin-top":"0.8vh"},on:{"click":function($event){return _vm.toCompon(
                      2,
                      'LTP_BB_MV',
                      'IOG',
                      'LTP_BB_MV_BM',
                      '2#引风液耦XK输出'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.LTP_BB_MV,
                      'LTP_BB_MV',
                      'IOG',
                      'LTP_BB_MV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.LTP_BB_MV)+"% ")])]),_c('div',{staticClass:"fl yinfeng-jg2 yinfeng-jg3"},[_c('div',{staticClass:"valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'YFI_A_PV',
                      'IOG',
                      'YFI_A_PV_BM',
                      '1#引风机电流'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.YFI_A_PV,
                      'YFI_A_PV',
                      'IOG',
                      'YCF_A_MV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.YFI_A_PV)+"A ")]),_c('div',{staticClass:"valuex yinfeng-jg4",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'YFI_B_PV',
                      'IOG',
                      'YFI_B_PV_BM',
                      '2#引风机电流'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.IOG.YFI_B_PV,
                      'YFI_B_PV',
                      'IOG',
                      'YFI_B_PV_BM'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.YFI_B_PV)+"A ")])]),_vm._m(0),_c('div',{staticClass:"fl yinfeng-jg5"},[_c('div',[_c('div',{staticClass:"bottonx fl buttonjg",style:({
                      background: !_vm.infoList.RQM__p__LTP_QK1.TS
                        ? '#2AFC30'
                        : 'red',
                    }),on:{"click":function($event){_vm.infoList.RQM__p__LTP_QK1
                        ? _vm.toDetail(
                            2,
                            'LTP_QK1',
                            'RQM__p__LTP_QK1',
                            '',
                            '炉膛负压前馈'
                          )
                        : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                      background: !_vm.infoList.RQM__p__LTP_RSF1.TS
                        ? '#2AFC30'
                        : 'red',
                    }),on:{"click":function($event){_vm.infoList.RQM__p__LTP_RSF1
                        ? _vm.toDetail(
                            3,
                            'LTP_RSF1',
                            'RQM__p__LTP_RSF1',
                            '',
                            '引风机电流纠偏'
                          )
                        : ''}}},[_vm._v(" R ")])]),_c('div',{staticStyle:{"margin-top":"3vh"}},[_c('div',{staticClass:"bottonx fl buttonjg",style:({
                      background: !_vm.infoList.XK__p__LTP_XK1.RM
                        ? '#2AFC30'
                        : 'red',
                    }),on:{"click":function($event){_vm.infoList.XK__p__LTP_XK1
                        ? _vm.toDetail(
                            1,
                            'LTP_XK1',
                            'XK__p__LTP_XK1',
                            '',
                            '引风先控调节'
                          )
                        : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                      background:
                        _vm.infoList.RQM__p__LTP_MAN1.RM == 1 ||
                        _vm.infoList.RQM__p__LTP_MAN2.RM == 1
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANYF', 2)}}},[_vm._v(" A ")])])])])])])])])]),(_vm.mflmanyManual)?_c('mflmanyManual',{attrs:{"titname":_vm.Manualtitname,"numM":_vm.Manualnode,"mark":_vm.Manualmark,"manvar":_vm.Manualvar,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.nodename,"Lkname":_vm.Aname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),_c('inputVal',{ref:"inputVal"}),(_vm.isPshow01)?_c('Param01',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isPshow02)?_c('Param02',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isPshow03)?_c('Param03',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isPshow04)?_c('Param04',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.iszqwdshow)?_c('MfZRQTC',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isPshow05)?_c('Param05',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isPshow06)?_c('Param06',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.MfMAC)?_c('MfMAC',{attrs:{"infoList":_vm.infoList,"MfMACCS1":_vm.MfMACCS1,"MfMACCS2":_vm.MfMACCS2,"MfMACCS3":_vm.MfMACCS3,"MfMACCS4":_vm.MfMACCS4},on:{"sendStatus":_vm.isClose}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fl yinfeng-jg5"},[_c('div',{staticClass:"widthx5 color1"},[_vm._v("引风机1#")]),_c('div',{staticClass:"widthx5 yinfeng-jg4 color1"},[_vm._v("引风机2#")])])}]

export { render, staticRenderFns }